import React from 'react';
import NavBar from "./NavBar";
import Footer from "./Footer";
import AboutMe from "./pages/AboutMe";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import { Route, Routes} from "react-router-dom";

function App() {
  return (
   <>
    <div className="container">
    <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Portfolio token={process.env.REACT_APP_INS_TOKEN} limit={53} />} />
        <Route path="/aboutme" element={<AboutMe />} />
      </Routes>
      <Footer />
    </div>
   </>
  )
}

export default App;
