import React from "react"
import Art from '../art.mp4'

export default function Home() {
    return (
        <div className="main">
            <video autoPlay loop muted playsInline>
                <source src={ Art } type="video/mp4"></source>
            </video>
            <div className="text-box">The<br></br>Fix<br></br>Mua</div> 
        </div>
    )
}