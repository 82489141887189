import React from 'react';
import pic_1 from './pic_1.jpeg'

const AboutMe = () => {
    return (
      <div className="pic-container">
        <img src= {pic_1} />
        <h1>Get to know me!</h1>
        <p>COMING SOON!</p>
      </div>
    )
}

export default AboutMe;