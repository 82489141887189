import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGoogle, faInstagram} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
    return (
        <footer className='footer'>
            <ul className='site-title'>Contact</ul>
            <ul>
                <a href="mailto: thefixmakeupartistry@gmail.com" className="google social">
                    <FontAwesomeIcon icon={faGoogle} size="2x" />
                </a>
                <a href="https://instagram.com/thefixmua?igshid=YmMyMTA2M2Y=" 
                target='_blank' className="instagram social" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
            </ul>
        </footer>
    );
}

